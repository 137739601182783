// Global
import Glide from '@glidejs/glide';
import Global from '../../base/_global';

// Modules Import
import Counter from '../../base/modules/_counter';

export default class HomePage {
  constructor() {
    // All time first instance IMPORTANT!!!
    new Global();

    this.maskedElements = [];
    window.animateCounter = Counter;
    this.languageButtonEvent();
    this.videoEvent();

  }
  languageButtonEvent() {
    $(".language").on("click", function (e) {
      e.stopPropagation();
      var menu = $(".language-menu");
      if (menu.css("opacity") == "0") {
        menu.css("opacity", "1");
        menu.css("pointer-events", "all");
      } else {
        menu.css("opacity", "0");
        menu.css("pointer-events", "none");
      }
    });

    $(document).on("click", function (e) {
      if (!$(e.target).closest(".language, .language-menu").length) {
        $(".language-menu").css("opacity", "0");
        $(".language-menu").css("pointer-events", "none");
      }
    });
  }
  videoEvent() {
    document.addEventListener("DOMContentLoaded", function () {
      const video = document.getElementById("backgroundVideo");
      const playButton = document.getElementById("playButton");
    
      function toggleVideo() {
        if (video.paused) {
          video.play();
          playButton.classList.add("hidden");
        } else {
          setTimeout(() => {
            video.pause();
            playButton.classList.remove("hidden");
          }, 200); // 200ms gecikme
        }
      }
    
      video.addEventListener("click", toggleVideo);
    
      playButton.addEventListener("click", function (event) {
        event.stopPropagation();
        toggleVideo();
      });
    });
    
  }
}

new HomePage();
